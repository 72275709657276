function setupPasswordVisibilityToggle(inputId, toggleId) {
    const toggleElement = document.getElementById(toggleId);

    toggleElement.addEventListener('click', function () {
        togglePasswordVisibility(inputId, toggleId);
    });
}

function togglePasswordVisibility(inputId, toggleId) {
    const passwordInput = document.getElementById(inputId);
    const type = passwordInput.type === 'password' ? 'text' : 'password';
    passwordInput.type = type;

    const icon = document.getElementById(toggleId)?.querySelector('span');
    if (icon) {
        icon.innerHTML = type === 'password' ? 'visibility' : 'visibility_off';
    }
}


function setupPasswordValidation() {
    const passwordInput = document.getElementById('password');
    const confirmPasswordInput = document.getElementById('confirm_password');
    const feedbackConfirmPassword = document.getElementById('feedback-confirm_password');

    passwordInput.addEventListener('input', validatePassword);
    confirmPasswordInput.addEventListener('input', validatePassword);

    function validatePassword() {
        if (passwordInput.value !== confirmPasswordInput.value) {
            feedbackConfirmPassword.textContent = 'Las contraseñas no coinciden.';
            confirmPasswordInput.setCustomValidity('Las contraseñas no coinciden.');
        } else {
            feedbackConfirmPassword.textContent = '';
            confirmPasswordInput.setCustomValidity('');
        }
    }
}

setupPasswordVisibilityToggle('password', 'togglePassword');
setupPasswordVisibilityToggle('confirm_password', 'toggleConfirmPassword');
setupPasswordValidation();