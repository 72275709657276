// Datepicker jQuery UI
require("jquery-ui/ui/widgets/datepicker");

jQuery(function () {

    var dateFormat = "dd/mm/yy",
        from = $( "#fecha_inicio" )
            .datepicker({
                yearRange: "2024:2050",
                showAnim: 'slideDown',
                showOn: "both",
                buttonImage: ubase + "/images/ico-date.svg",
                changeYear: true,
                changeMonth: true,
                // maxDate: '0',
                altField: '#fecha_inicio',
                // onSelect: function (selectedDate) {
                //     var option = this.id == "fecha_inicio" ? "minDate" : "maxDate";
                //     var instance = $(this).data("datepicker");
                //     var date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, selectedDate, instance.settings);
                //     // console.log(this.id + " / " + this.val + " / " + instance + " / " + selectedDate)
                //     from.not(this).datepicker("option", option, date);
                // }
            })
            .on( "change", function() {
                to.datepicker( "option", "minDate", getDate( this ) );
            }),
        to = $( "#fecha_fin" )
            .datepicker({
                yearRange: "2024:2050",
                showAnim: 'slideDown',
                showOn: "both",
                buttonImage: ubase + "/images/ico-date.svg",
                changeYear: true,
                changeMonth: true,
                // maxDate: '0',
                altField: '#fecha_fin',
                // onSelect: function (selectedDate) {
                //     var option = this.id == "fecha_fin" ? "minDate" : "maxDate";
                //     var instance = $(this).data("datepicker");
                //     var date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, selectedDate, instance.settings);
                //     // console.log(this.id + " / " + this.val + " / " + instance + " / " + selectedDate)
                //     to.not(this).datepicker("option", option, date);
                // }
            })
            .on( "change", function() {
                from.datepicker( "option", "maxDate", getDate( this ) );
            })
    ;

    function getDate( element ) {
        var date;
        try {
            date = $.datepicker.parseDate( dateFormat, element.value );
        } catch( error ) {
            date = null;
        }
        return date;
    }
  

    var dates = $("#fecha_evento").datepicker({
        yearRange: "2024:2050",
        showAnim: 'slideDown',
        showOn: "both",
        buttonImage: ubase + "/images/ico-date.svg",
        changeYear: true,
        changeMonth: true,
        // maxDate: '0',
        minDate: 0,
        altField: '#fecha_evento',
        onSelect: function (selectedDate) {
            var option = this.id == "fecha_evento" ? "minDate" : "maxDate";
            var instance = $(this).data("datepicker");
            var date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, selectedDate, instance.settings);
            // console.log(this.id + " / " + this.val + " / " + instance + " / " + selectedDate)
            dates.not(this).datepicker("option", option, date);

        }
    });

    // Traducción - Español
    $.datepicker.regional['es'] = {
        closeText: 'Cerrar',
        prevText: '<',
        nextText: '>',
        currentText: 'Hoy',
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: true,
        yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['es']);
});
