document.addEventListener('DOMContentLoaded', function () {
    const eventForm = document.getElementById('event-form');

    if (eventForm) {
        const select = document.getElementById('id_tipo_evento');
        const partials = document.querySelectorAll('.event-partial');

        // Constantes para los tipos de eventos
        const TOUR = '1';
        const ASAMBLEA = '2';
        const ACTIVACION = '3';
        const MESA = '4';

        // Mapeo de constantes a IDs de contenedores
        const containerIds = {
            [TOUR]: 'container-tour',
            [ASAMBLEA]: 'container-asamblea',
            [ACTIVACION]: 'container-activacion',
            [MESA]: 'container-mesa'
        };


        select.addEventListener('change', function () {
            // Ocultar todos los partials y limpiar y deshabilitar los inputs
            partials.forEach(partial => {
                partial.classList.add('d-none');
                partial.querySelectorAll('input').forEach(input => {
                    input.setAttribute('disabled', 'disabled');
                    if (input.type == "radio") {
                        input.checked = false;
                    }
                });

            });

            // Obtener el valor seleccionado
            const selection = select.value;

            // Si es mesa
            if(selection == MESA){
                document.getElementById('btn-send').disabled=false;
            }else{
                document.getElementById('btn-send').disabled=true;
            }

            // Si es Tour
            if(selection == TOUR){
                $('#id_alcaldia').attr('required', false);
                $('#id_colonia').attr('required', false);
                $('.reqnot').addClass('d-none');
            }else{
                $('#id_alcaldia').attr('required', true);
                $('#id_colonia').attr('required', true);
                $('.reqnot').removeClass('d-none');
            }

            // Mostrar el partial correspondiente
            const containerId = containerIds[selection];
            const containerToShow = document.getElementById(containerId);

            if (containerToShow) {
                containerToShow.classList.remove('d-none');
                containerToShow.querySelectorAll('input').forEach(input => {
                    if(input.id !== 'nu_cupo_l'){
                        input.removeAttribute('disabled');
                    }
                });
            }
        });
    }

    const hasCupoLimitadoRadios = document.getElementsByName('is_cupo_limitado');

    hasCupoLimitadoRadios.forEach(function (radio) {
        radio.addEventListener('change', function (e) {
            r = e.target;
            desde = r.dataset.trigger;
            hasta =  document.querySelector('[data-target="'+desde+'"]');
            if (r.checked) {
                if(r.value =='true'){
                    hasta.classList.remove('d-none');
                    hasta.disabled=false;
                }else{
                    hasta.classList.add('d-none');
                    hasta.disabled = true;
                }
                document.getElementById('btn-send').disabled=false;
            } else {
            }
        });
    });
});

//tpPersona=document.querySelector('input[name="tp-persona"]:checked').value;